.Contact {
  display: grid;
  grid-template-columns: 0.6fr 0.45fr;

  .content-block {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;

    h2 {
      margin-bottom: 20px;
    }

    .LinkButton {
      padding: 5px 10px;
      border: 2px solid black;
      font-size: 24px;
      transition: 0.2s ease;

      .icon {
        svg {
          height: 30px;
          width: 30px;
        }
      }
    }

    .socials-links-wrapper {
      display: flex;
      align-items: center;

      p {
        margin-right: 15px;
      }
    }
  }

  .image-block {
    position: relative;
    z-index: 2;
    height: 288px;
    background-position: center;
    background-size: cover;

    .contact-img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 810px) {
  .Contact {
    grid-template-columns: 1fr;

    .content-block {
      text-align: center;
      align-items: center;
    }
  }
}
