.Presentation {
  margin-top: 150px;
  display: grid;
  grid-template-columns: 0.6fr 0.45fr;

  .title-block {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 45%;
    background-color: var(--secondary-color);

    .subtitle {
      margin-top: 8px;
      font-weight: var(--font-bold);
      font-size: 30px;
      line-height: normal;
      text-align: left;
    }
  }

  .presentation-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 55%;
    gap: 15px;
  }

  .image-block {
    position: relative;
    z-index: 3;
    height: 649px;
    background-position: center;
    background-size: cover;
  }
}

@media (max-width: 810px) {
  .Presentation {
    grid-template-columns: 1fr;

    .title-block {
      height: auto;
      text-align: center;

      .subtitle {
        text-align: center;
      }
    }

    .presentation-block {
      height: auto;
    }

    .image-block {
      order: -1;
      height: 620px;
      background-position: bottom center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
