.logo {
  max-width: var(--wrapper-width);
  margin: 0 auto 20px auto;
  display: flex;
  justify-content: center;

  img {
    max-width: 500px;
  }
}

.wrapper-nav {
  width: 100%;

  .wrapper-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
  }
}

.desktop-nav {
  margin: 0 auto;
  max-width: var(--wrapper-width);
}

.mobile-nav {
  display: none;
  position: relative;

  .mobile-menu-icon {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      height: auto;
      width: 30px;
    }
  }

  .mobile-menu {
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: space-around;
    height: 470px;
    width: 100%;
    background-color: white;
    padding-bottom: 20px;
    z-index: 100;

    -webkit-box-shadow: 0px 11px 17px -1px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 11px 17px -1px rgba(0, 0, 0, 0.2);
  }

  .mobile-menu-overlay {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.3;
    z-index: 99;
  }
}

.footer-nav {
  width: 300px;
  filter: invert(1);
}

nav {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 620px;

  .nav-link {
    display: flex;
    align-items: center;
    margin: 0 10px;
    height: 40px;
    white-space: nowrap;
    transition: 0.2s ease;

    &:hover {
      padding-bottom: 5px;
    }
  }
}

@media (max-width: 1024px) {
  .wrapper-nav {
    padding: 0 15px;
  }
}

@media (max-width: 810px) {
  .wrapper-nav {
    padding: 0;
  }

  .desktop-nav {
    display: none;
  }

  .mobile-nav {
    display: block;
  }

  nav {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    max-width: 810px;

    .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      min-height: 20px;
      white-space: nowrap;
    }
  }

  .SocialsLinks {
    justify-content: center;
  }
}

@media (max-width: 550px) {
  .logo {
    img {
      max-width: 300px;
    }
  }
}
