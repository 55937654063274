.Member {
  display: grid;
  grid-template-columns: 0.6fr 0.45fr;

  .text-block {
    padding-top: 50px;
    padding-bottom: 50px;
    .name {
      margin-top: 8px;
      font-weight: var(--font-bold);
      font-size: 30px;
      line-height: normal;
      text-align: left;
    }

    .subtitle {
      font-weight: var(--font-bold);
      color: #8e8e8e;
      margin-bottom: 3px;
    }

    .category {
      margin-bottom: 20px;
    }
  }

  .image-block {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    width: 100%;
    background-color: var(--secondary-color);

    .member-img{
      width: 250px;
      height: 250px;
    }
  }
}

@media (max-width: 710px) {
  .Member  {
    grid-template-columns: 1fr;

    .text-block {
      text-align: center;

      .name {
        text-align: center;
      }
    }

    .image-block {
      order: -1;
    }
  }
}
