.SocialsLinks {
  display: flex;
  gap: 15px;

  .social-icon {
    display: flex;
    align-items: center;
    transition: 0.2s ease;

    &:hover {
      transform: scale(1.1);
    }

    svg {
      height: auto;
      width: 25px;
    }
  }
}
