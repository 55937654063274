/* Variables et imports */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

:root {
  --font-family: "Roboto", sans-serif;
  --font-medium: 500;
  --font-bold: 700;
  --wrapper-width: 1024px;
  --main-color: #fff960;
  --secondary-color: #b8f0ed;
}
