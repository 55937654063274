/* App styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family);
}

a {
  color: unset;
  text-decoration: none;
}

h4 {
  font-weight: var(--font-bold);
  font-size: 35px;
}

h3 {
  font-weight: var(--font-medium);
  font-size: 25px;
}

h2 {
  font-weight: var(--font-bold);
  font-size: 55px;
  line-height: 45px;
}

.wrapper {
  max-width: var(--wrapper-width);
  margin: 0 auto;
}

.content-block {
  padding: 0 50px;

  p {
    line-height: 21px;
  }
}

.justify {
  text-align: justify;
}

@media (max-width: 810px) {
  .content-block {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: var(--main-color);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
}

// Popup FB
.FBPopup {
  position: fixed;
  z-index: 50;
  display: flex;
  right: 40px;
  bottom: 25px;
  
  .text {
    position: relative;
    z-index: 49;
    opacity: 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    text-align: right;
    transition-duration: 1s;
    border-radius: 40px 0 0 40px;
    padding: 15px;
    transform: translateX(50px);
  }

  .icon {
    position: relative;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 40px;
    height: 80px;
    width: 80px;
    -webkit-box-shadow: 5px 7px 17px -5px rgba(0,0,0,0.49); 
    box-shadow: 5px 7px 17px -5px rgba(0,0,0,0.49);
    transition-duration: 1s;
    svg {
      min-width: 40px;
      min-height: 40px;
    }
  }

  &:hover {
    .icon {
      border-radius: 0px 40px 40px 0;
    }
    .text {
      opacity: 1;
      transform: translateX(0);
      background-color: white;
    }
  }
  
}

@media (max-width: 715px) {
  .FBPopup {
    display: none;
  }
}
