.News {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 150px;

  .grid-item {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      text-align: center;
      margin-bottom: 45px;
    }

    .fb-page {
      display: flex;
      justify-content: center;
    }
  }
}
