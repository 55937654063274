.VideoPlayer {
  margin-top: 210px;
  display: grid;
  grid-template-columns: 0.45fr 0.6fr;

  .affiche-block {
    position: relative;
    z-index: 3;
    height: 589px;
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .title-block {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 50%;
    padding-bottom: 30px;

    .subtitle {
      font-weight: var(--font-bold);
      color: #8e8e8e;
      margin-bottom: 3px;
    }
  }

  .synopsis-block {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50%;
    background-color: var(--main-color);

    .synopsis {
      margin-bottom: 25px;
    }
  }

  .interview-block {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--main-color);
    height: 333px;

    h4 {
      text-align: center;
      margin-bottom: 25px;
    }
  }

  .interview-img {
    position: relative;
    z-index: 3;
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 810px) {
  .VideoPlayer {
    grid-template-columns: 1fr;
    margin-top: 150px;

    .title-block {
      text-align: center;

      .content-block p {
        text-align: center;
      }
    }

    .synopsis-block {
      align-items: center;
    }

    .interview-block {
      order: 4;
      height: 250px;
    }

    .interview-img {
      order: 3;
    }
  }
}
