.LinkButton {
  display: flex;
  align-items: center;
  width: fit-content;
  font-weight: var(--font-medium);

  span {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  .icon {
    transition: 0.2s ease;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  &:hover {
    .icon {
      transform: translateX(5px);
    }
  }
}
