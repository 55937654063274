.ActionsList {
  position: relative;
  display: grid;
  grid-template-columns: 0.6fr 0.45fr;

  .list-block {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: var(--secondary-color);
    min-height: 805px;
    padding: 45px;

    .notSelected {
      opacity: 0.2;
    }

    .selected {
      padding-right: 10px;
    }

    p {
      font-weight: var(--font-bold);
      font-size: 24px;
      text-align: right;
      transition: 0.3s ease;
      cursor: pointer;

      &:hover {
        padding-right: 10px;
      }
    }
  }

  .text-block {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 45px;

    h2 {
      margin-bottom: 35px;
    }

    .back {
      margin-top: 10px;
      display: flex;
      align-items: center;
      color: #8e8e8e;
      cursor: pointer;
    }

    .text {
      flex: 1;
      p {
        margin-top: 25px;
      }
    }
  }
}

.TextPopup {
  padding: 50px;
  z-index: 8;
  background-color: white;
  position: absolute;
  height: 100%;
  border: 15px solid var(--secondary-color);
}

@media (max-width: 810px) {
  .ActionsList {
    grid-template-columns: 1fr;

    .list-block {
      min-height: 550px;
      p {
        text-align: center;

        &:hover {
          padding-right: 0;
        }
      }
    }

    .text-block {
      min-height: 525px;
      h2 {
        text-align: center;
      }
    }
  }
}
